import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
// import Seo from "../components/Seo"
// import {useTranslation} from 'gatsby-plugin-react-i18next';
import { connect } from 'react-redux';
import useWindowResize from "../components/Hooks/useWindowResize"
import Helper from '@justnice/helper';

import { useMediaQuery } from '@material-ui/core';
import Markdown from 'markdown-to-jsx';

import * as systemActions from '../redux/actions/systemActions';

import white_myself from '../images/aboutus_bg.png';
import black_myself from '../images/aboutus_bg.png';

const AboutPage : React.FC = (props) => {
  // const {t} = useTranslation('about');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const windowSize = useWindowResize();
  const contentList = Helper.getStateAsJSObj(props['system'], 'content.data', []);
  let selectedContent = null;
  const locale = props.ui.get('lang');

  // console.log('contentList: ', contentList);

  if(Helper.arrayHasItem(contentList))
    selectedContent = contentList.find(item => item.category === 'WEBCONTENT' && item.type === 'ABOUT_US');

  // console.log('selectedContent: ', selectedContent);
  
  if(!Helper.isNotNullAndUndefined(selectedContent)) {
    selectedContent = {
      title: '',
      content: ''
    };
  }

  // console.log('selectedContent2: ', selectedContent);

  React.useEffect(() => {
    const contentObj = Helper.getStateAsJSObj(props['system'], 'content', null);
    const aboutContent = Helper.arrayHasItem(contentObj?.data) ? contentObj.data.find(item => item.category === 'WEBCONTENT' && item.type === 'ABOUT_US') : null;

    // If no content info and content list is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(contentObj) || 
       (!Helper.arrayHasItem(contentObj?.data) && !contentObj?.loading) ||
       (contentObj?.locale !== locale && !contentObj?.loading) ||
       (!Helper.isNotNullAndUndefined(aboutContent) && !contentObj?.loading)) {
      props.dispatch(
        systemActions.contentListGet(locale)
      );
    }


  }, []);

  return(<Layout title={selectedContent.title} authRequired={false} 
    backgroundImage={prefersDarkMode ? black_myself : white_myself}
    backgroundStyle={{
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}
    >
    {/* <Seo title={selectedContent.title} /> */}
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: windowSize.height * 0.8
    }}>
      <Markdown style={{opacity: 1, marginTop: 10, whiteSpace: 'pre-wrap'}}>
        {selectedContent.content}
      </Markdown>
    </div>
    
    
  </Layout>)}

export default connect((state) => {
  return {
    ui: state['ui'],
    session: state['session'],
    system: state['system'],
    page: state['page']
  };
})(AboutPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;